.btn,
button {
  background: $color-second;
  border: 1px solid $color-second;
  padding: $spacing-base;
  color: $color-white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
  display: inline-block;

  &:hover {
    background: lighten($color-main, $amount: 30);
  }
}
