.image {
  width: 100%;
  height: 100%;
  display: block;

  &--16-9 {
    aspect-ratio: 16 / 9;
  }

  &--2-1 {
    aspect-ratio: 2 / 1;
  }

  &--3-1 {
    aspect-ratio: 3 / 1;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}
