.hero__inner {
  position: relative;
}

.hero__logo {
  margin-bottom: 2rem;

  svg {
    max-width: 100%;
  }
}

.hero__headline {
  font-size: 45px;
  padding-left: 2rem;

  h1 {
    // font-weight: $font-normal;
    font-weight: $font-light;
    margin-bottom: 0;
  }

  @media (min-width: $md) {
    max-width: 80%;
    padding-left: 4rem;
    position: absolute;
    bottom: 0;
    left: 0;
    // transform: translateY(24%);

    h1 {
      font-weight: $font-light;
      font-size: 65px;
      letter-spacing: 30px;
    }
  }
}

.hero__layer {
  position: absolute;
  top: 80px;
  right: -1rem;
  //   right: -$spacing-xl;
  bottom: 0;
  left: 50%;
  background: $color-second;
  display: block;
  mix-blend-mode: lighten;

  @media (min-width: $md) {
    top: 0;
  }
}

.hero__image {
  margin-bottom: $spacing-lg;
  position: relative;
  aspect-ratio: 3 / 2;

  .image {
    height: 100%;
  }

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, $color-main 100%);
    content: "";
  }

  @media (min-width: $md) {
    margin-bottom: 0;
    aspect-ratio: 3 / 1;
  }
}
