@mixin screen($breakpoint, $start: "min") {
  $maxBreakpoint: $breakpoint - 1;
  @if $start == "min" {
    @media (min-width: $breakpoint) {
      @content;
    }
  } @else {
    @media (max-width: $maxBreakpoint) {
      @content;
    }
  }
}
