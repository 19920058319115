.gigs__row {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;

  @media (min-width: $md) {
    margin-bottom: 0;
    flex-direction: row;
    gap: $spacing-xl;
  }
}

.gigs__content {
  // margin-bottom: $spacing-xl;

  @media (min-width: $md) {
    width: 60%;
  }
}

.gigs__headline {
  border-bottom: 2px solid $color-second;
  //   display: inline-block;
  margin-bottom: $spacing-lg;
}

.gigs__list {
  margin-bottom: $spacing-xxl;

  @media (min-width: $md) {
    font-size: $text-md;
    // margin-bottom: $spacing-xxl;
  }
}

.gigs__image {
  position: relative;
  padding-bottom: $spacing-lg;
  order: -1;
  margin-bottom: $spacing-xl;

  @media (min-width: $md) {
    margin-bottom: 0;
    width: 40%;
    order: 2;
  }
}

.gigs__layer {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 50%;
  height: auto;
  aspect-ratio: 1 / 1;
  background: $color-second;
  mix-blend-mode: lighten;
}

.gigs__list-inner {
  display: flex;
  flex-direction: column;
  gap: $spacing-lg;
}

.gigs__item {
  display: flex;
  justify-content: space-between;
  gap: $spacing-sm;

  & + & {
    border-top: solid 1px $color-second;
    padding-top: $spacing-lg;
  }
}

.gigs__item-date {
  font-weight: $font-light;
  flex: 0 0 50%;
}

.gigs__item-additional {
  color: $color-second;
}
