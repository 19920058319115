h1,
.h1,
h2,
.h2,
h3,
.h3 {
  font-weight: $font-light;
  margin-top: 0;
  text-transform: uppercase;
  line-height: 1.2;

  p {
    margin: 0;
  }
}

h1,
.h1 {
  font-size: clamp($text-xl, 5vw, $text-xxxl);
  margin-bottom: $spacing-xl;
}

h2,
.h2 {
  font-size: clamp($text-lg, 3vw, $text-xl);
  margin-bottom: $spacing-lg;
  font-weight: $font-bold;
}

h3,
.h3 {
  font-size: $text-md;
  margin-bottom: $spacing-md;
}

h4,
.h4 {
  font-size: $text-base;
  font-weight: $font-bold;
}
