.no-margin {
  margin: 0;
}

.small-margin {
}

.no-padding {
  padding: 0;
}

.small-padding {
}

.mb-base {
  margin-bottom: $spacing-base;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}
