.modal {
  display: none;
  position: fixed;
  inset: 0;
  z-index: 200;
  align-items: center;
  justify-content: center;
  padding: $spacing-base;

  &.active {
    display: flex;
  }
}

.modal__background {
  position: fixed;
  inset: 0;
  background: rgba($color-black, 0.8);
}

.modal__window {
  max-width: $md;
  max-height: 80vh;
  width: 100%;
  height: 100%;
  overflow: scroll;
  background: $color-white;
  padding: $spacing-xl;
  position: relative;
}

.modal__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: $spacing-sm;
  cursor: pointer;
}
