.animate-observe {
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}

.animate {
  position: relative;

  &--reveal {
    clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
    animation: reveal 1s forwards;
  }

  &--delay-1 {
    animation-delay: 0.1s;
  }

  &--delay-2 {
    animation-delay: 0.2s;
  }

  &--delay-3 {
    animation-delay: 0.3s;
  }
}

@keyframes reveal {
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}
