html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted buttontext;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

@font-face {
  font-family: icomoon;
  src: url("icomoon.08d4b3ba.ttf") format("truetype"), url("icomoon.516d180f.woff") format("woff"), url("icomoon.ca4a23a9.svg#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  speak: never;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  font-family: icomoon !important;
}

.icon-activity:before {
  content: "";
}

.icon-airplay:before {
  content: "";
}

.icon-alert-circle:before {
  content: "";
}

.icon-alert-octagon:before {
  content: "";
}

.icon-alert-triangle:before {
  content: "";
}

.icon-align-center:before {
  content: "";
}

.icon-align-justify:before {
  content: "";
}

.icon-align-left:before {
  content: "";
}

.icon-align-right:before {
  content: "";
}

.icon-anchor:before {
  content: "";
}

.icon-aperture:before {
  content: "";
}

.icon-archive:before {
  content: "";
}

.icon-arrow-down:before {
  content: "";
}

.icon-arrow-down-circle:before {
  content: "";
}

.icon-arrow-down-left:before {
  content: "";
}

.icon-arrow-down-right:before {
  content: "";
}

.icon-arrow-left:before {
  content: "";
}

.icon-arrow-left-circle:before {
  content: "";
}

.icon-arrow-right:before {
  content: "";
}

.icon-arrow-right-circle:before {
  content: "";
}

.icon-arrow-up:before {
  content: "";
}

.icon-arrow-up-circle:before {
  content: "";
}

.icon-arrow-up-left:before {
  content: "";
}

.icon-arrow-up-right:before {
  content: "";
}

.icon-at-sign:before {
  content: "";
}

.icon-award:before {
  content: "";
}

.icon-bar-chart:before {
  content: "";
}

.icon-bar-chart-2:before {
  content: "";
}

.icon-battery:before {
  content: "";
}

.icon-battery-charging:before {
  content: "";
}

.icon-bell:before {
  content: "";
}

.icon-bell-off:before {
  content: "";
}

.icon-bluetooth:before {
  content: "";
}

.icon-bold:before {
  content: "";
}

.icon-book:before {
  content: "";
}

.icon-book-open:before {
  content: "";
}

.icon-bookmark:before {
  content: "";
}

.icon-box:before {
  content: "";
}

.icon-briefcase:before {
  content: "";
}

.icon-calendar:before {
  content: "";
}

.icon-camera:before {
  content: "";
}

.icon-camera-off:before {
  content: "";
}

.icon-cast:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-check-circle:before {
  content: "";
}

.icon-check-square:before {
  content: "";
}

.icon-chevron-down:before {
  content: "";
}

.icon-chevron-left:before {
  content: "";
}

.icon-chevron-right:before {
  content: "";
}

.icon-chevron-up:before {
  content: "";
}

.icon-chevrons-down:before {
  content: "";
}

.icon-chevrons-left:before {
  content: "";
}

.icon-chevrons-right:before {
  content: "";
}

.icon-chevrons-up:before {
  content: "";
}

.icon-chrome:before {
  content: "";
}

.icon-circle:before {
  content: "";
}

.icon-clipboard:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-cloud:before {
  content: "";
}

.icon-cloud-drizzle:before {
  content: "";
}

.icon-cloud-lightning:before {
  content: "";
}

.icon-cloud-off:before {
  content: "";
}

.icon-cloud-rain:before {
  content: "";
}

.icon-cloud-snow:before {
  content: "";
}

.icon-code:before {
  content: "";
}

.icon-codepen:before {
  content: "";
}

.icon-codesandbox:before {
  content: "";
}

.icon-coffee:before {
  content: "";
}

.icon-columns:before {
  content: "";
}

.icon-command:before {
  content: "";
}

.icon-compass:before {
  content: "";
}

.icon-copy:before {
  content: "";
}

.icon-corner-down-left:before {
  content: "";
}

.icon-corner-down-right:before {
  content: "";
}

.icon-corner-left-down:before {
  content: "";
}

.icon-corner-left-up:before {
  content: "";
}

.icon-corner-right-down:before {
  content: "";
}

.icon-corner-right-up:before {
  content: "";
}

.icon-corner-up-left:before {
  content: "";
}

.icon-corner-up-right:before {
  content: "";
}

.icon-cpu:before {
  content: "";
}

.icon-credit-card:before {
  content: "";
}

.icon-crop:before {
  content: "";
}

.icon-crosshair:before {
  content: "";
}

.icon-database:before {
  content: "";
}

.icon-delete:before {
  content: "";
}

.icon-disc:before {
  content: "";
}

.icon-dollar-sign:before {
  content: "";
}

.icon-download:before {
  content: "";
}

.icon-download-cloud:before {
  content: "";
}

.icon-droplet:before {
  content: "";
}

.icon-edit:before {
  content: "";
}

.icon-edit-2:before {
  content: "";
}

.icon-edit-3:before {
  content: "";
}

.icon-external-link:before {
  content: "";
}

.icon-eye:before {
  content: "";
}

.icon-eye-off:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-fast-forward:before {
  content: "";
}

.icon-feather:before {
  content: "";
}

.icon-figma:before {
  content: "";
}

.icon-file:before {
  content: "";
}

.icon-file-minus:before {
  content: "";
}

.icon-file-plus:before {
  content: "";
}

.icon-file-text:before {
  content: "";
}

.icon-film:before {
  content: "";
}

.icon-filter:before {
  content: "";
}

.icon-flag:before {
  content: "";
}

.icon-folder:before {
  content: "";
}

.icon-folder-minus:before {
  content: "";
}

.icon-folder-plus:before {
  content: "";
}

.icon-framer:before {
  content: "";
}

.icon-frown:before {
  content: "";
}

.icon-gift:before {
  content: "";
}

.icon-git-branch:before {
  content: "";
}

.icon-git-commit:before {
  content: "";
}

.icon-git-merge:before {
  content: "";
}

.icon-git-pull-request:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-gitlab:before {
  content: "";
}

.icon-globe:before {
  content: "";
}

.icon-grid:before {
  content: "";
}

.icon-hard-drive:before {
  content: "";
}

.icon-hash:before {
  content: "";
}

.icon-headphones:before {
  content: "";
}

.icon-heart:before {
  content: "";
}

.icon-help-circle:before {
  content: "";
}

.icon-hexagon:before {
  content: "";
}

.icon-home:before {
  content: "";
}

.icon-image:before {
  content: "";
}

.icon-inbox:before {
  content: "";
}

.icon-info:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-italic:before {
  content: "";
}

.icon-key:before {
  content: "";
}

.icon-layers:before {
  content: "";
}

.icon-layout:before {
  content: "";
}

.icon-life-buoy:before {
  content: "";
}

.icon-link:before {
  content: "";
}

.icon-link-2:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-list:before {
  content: "";
}

.icon-loader:before {
  content: "";
}

.icon-lock:before {
  content: "";
}

.icon-log-in:before {
  content: "";
}

.icon-log-out:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-map:before {
  content: "";
}

.icon-map-pin:before {
  content: "";
}

.icon-maximize:before {
  content: "";
}

.icon-maximize-2:before {
  content: "";
}

.icon-meh:before {
  content: "";
}

.icon-menu:before {
  content: "";
}

.icon-message-circle:before {
  content: "";
}

.icon-message-square:before {
  content: "";
}

.icon-mic:before {
  content: "";
}

.icon-mic-off:before {
  content: "";
}

.icon-minimize:before {
  content: "";
}

.icon-minimize-2:before {
  content: "";
}

.icon-minus:before {
  content: "";
}

.icon-minus-circle:before {
  content: "";
}

.icon-minus-square:before {
  content: "";
}

.icon-monitor:before {
  content: "";
}

.icon-moon:before {
  content: "";
}

.icon-more-horizontal:before {
  content: "";
}

.icon-more-vertical:before {
  content: "";
}

.icon-mouse-pointer:before {
  content: "";
}

.icon-move:before {
  content: "";
}

.icon-music:before {
  content: "";
}

.icon-navigation:before {
  content: "";
}

.icon-navigation-2:before {
  content: "";
}

.icon-octagon:before {
  content: "";
}

.icon-package:before {
  content: "";
}

.icon-paperclip:before {
  content: "";
}

.icon-pause:before {
  content: "";
}

.icon-pause-circle:before {
  content: "";
}

.icon-pen-tool:before {
  content: "";
}

.icon-percent:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-phone-call:before {
  content: "";
}

.icon-phone-forwarded:before {
  content: "";
}

.icon-phone-incoming:before {
  content: "";
}

.icon-phone-missed:before {
  content: "";
}

.icon-phone-off:before {
  content: "";
}

.icon-phone-outgoing:before {
  content: "";
}

.icon-pie-chart:before {
  content: "";
}

.icon-play:before {
  content: "";
}

.icon-play-circle:before {
  content: "";
}

.icon-plus:before {
  content: "";
}

.icon-plus-circle:before {
  content: "";
}

.icon-plus-square:before {
  content: "";
}

.icon-pocket:before {
  content: "";
}

.icon-power:before {
  content: "";
}

.icon-printer:before {
  content: "";
}

.icon-radio:before {
  content: "";
}

.icon-refresh-ccw:before {
  content: "";
}

.icon-refresh-cw:before {
  content: "";
}

.icon-repeat:before {
  content: "";
}

.icon-rewind:before {
  content: "";
}

.icon-rotate-ccw:before {
  content: "";
}

.icon-rotate-cw:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-save:before {
  content: "";
}

.icon-scissors:before {
  content: "";
}

.icon-search:before {
  content: "";
}

.icon-send:before {
  content: "";
}

.icon-server:before {
  content: "";
}

.icon-settings:before {
  content: "";
}

.icon-share:before {
  content: "";
}

.icon-share-2:before {
  content: "";
}

.icon-shield:before {
  content: "";
}

.icon-shield-off:before {
  content: "";
}

.icon-shopping-bag:before {
  content: "";
}

.icon-shopping-cart:before {
  content: "";
}

.icon-shuffle:before {
  content: "";
}

.icon-sidebar:before {
  content: "";
}

.icon-skip-back:before {
  content: "";
}

.icon-skip-forward:before {
  content: "";
}

.icon-slack:before {
  content: "";
}

.icon-slash:before {
  content: "";
}

.icon-sliders:before {
  content: "";
}

.icon-smartphone:before {
  content: "";
}

.icon-smile:before {
  content: "";
}

.icon-speaker:before {
  content: "";
}

.icon-square:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-stop-circle:before {
  content: "";
}

.icon-sun:before {
  content: "";
}

.icon-sunrise:before {
  content: "";
}

.icon-sunset:before {
  content: "";
}

.icon-tablet:before {
  content: "";
}

.icon-tag:before {
  content: "";
}

.icon-target:before {
  content: "";
}

.icon-terminal:before {
  content: "";
}

.icon-thermometer:before {
  content: "";
}

.icon-thumbs-down:before {
  content: "";
}

.icon-thumbs-up:before {
  content: "";
}

.icon-toggle-left:before {
  content: "";
}

.icon-toggle-right:before {
  content: "";
}

.icon-tool:before {
  content: "";
}

.icon-trash:before {
  content: "";
}

.icon-trash-2:before {
  content: "";
}

.icon-trello:before {
  content: "";
}

.icon-trending-down:before {
  content: "";
}

.icon-trending-up:before {
  content: "";
}

.icon-triangle:before {
  content: "";
}

.icon-truck:before {
  content: "";
}

.icon-tv:before {
  content: "";
}

.icon-twitch:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-type:before {
  content: "";
}

.icon-umbrella:before {
  content: "";
}

.icon-underline:before {
  content: "";
}

.icon-unlock:before {
  content: "";
}

.icon-upload:before {
  content: "";
}

.icon-upload-cloud:before {
  content: "";
}

.icon-user:before {
  content: "";
}

.icon-user-check:before {
  content: "";
}

.icon-user-minus:before {
  content: "";
}

.icon-user-plus:before {
  content: "";
}

.icon-user-x:before {
  content: "";
}

.icon-users:before {
  content: "";
}

.icon-video:before {
  content: "";
}

.icon-video-off:before {
  content: "";
}

.icon-voicemail:before {
  content: "";
}

.icon-volume:before {
  content: "";
}

.icon-volume-1:before {
  content: "";
}

.icon-volume-2:before {
  content: "";
}

.icon-volume-x:before {
  content: "";
}

.icon-watch:before {
  content: "";
}

.icon-wifi:before {
  content: "";
}

.icon-wifi-off:before {
  content: "";
}

.icon-wind:before {
  content: "";
}

.icon-x:before {
  content: "";
}

.icon-x-circle:before {
  content: "";
}

.icon-x-octagon:before {
  content: "";
}

.icon-x-square:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-zap:before {
  content: "";
}

.icon-zap-off:before {
  content: "";
}

.icon-zoom-in:before {
  content: "";
}

.icon-zoom-out:before {
  content: "";
}

.no-margin {
  margin: 0;
}

.no-padding {
  padding: 0;
}

.mb-base {
  margin-bottom: 1rem;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 200;
  src: url("nunito-sans-v15-latin-200.625becd9.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 400;
  src: url("nunito-sans-v15-latin-regular.d48c2b06.woff2") format("woff2");
}

@font-face {
  font-display: swap;
  font-family: Nunito Sans;
  font-style: normal;
  font-weight: 800;
  src: url("nunito-sans-v15-latin-800.ccf3a769.woff2") format("woff2");
}

html {
  box-sizing: border-box;
  font-family: Nunito Sans;
  font-size: 16px;
  font-weight: 400;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  background: #1e1e1e;
  font-size: 1rem;
  line-height: 1.6;
}

body :focus {
  background: none;
  outline: none;
}

* {
  box-sizing: border-box;
}

section, .section {
  padding: clamp(2rem, 10vw, 6.5rem) 0;
  overflow: hidden;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 1rem;
}

@media (width >= 769px) {
  .container {
    padding: 0 2rem;
  }
}

.container--small {
  max-width: 769px;
}

.container-fluid {
  padding: 0 5vw;
}

.row {
  margin-left: -1rem;
  margin-right: -1rem;
}

@media (width >= 769px) {
  .row {
    margin-left: -2rem;
    margin-right: -2rem;
    display: flex;
  }
}

.col {
  padding: 0 1rem;
}

@media (width >= 769px) {
  .col {
    padding: 0 2rem;
  }
}

img {
  width: 100%;
  height: auto;
}

h1, .h1, h2, .h2, h3, .h3 {
  text-transform: uppercase;
  margin-top: 0;
  font-weight: 200;
  line-height: 1.2;
}

h1 p, .h1 p, h2 p, .h2 p, h3 p, .h3 p {
  margin: 0;
}

h1, .h1 {
  margin-bottom: 2rem;
  font-size: clamp(2rem, 5vw, 5rem);
}

h2, .h2 {
  margin-bottom: 1.5rem;
  font-size: clamp(1.5rem, 3vw, 2rem);
  font-weight: 700;
}

h3, .h3 {
  margin-bottom: 1.25rem;
  font-size: 1.25rem;
}

h4, .h4 {
  font-size: 1rem;
  font-weight: 700;
}

.animate-observe {
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
}

.animate {
  position: relative;
}

.animate--reveal {
  clip-path: polygon(0% 0%, 0% 0%, 0% 100%, 0% 100%);
  animation: 1s forwards reveal;
}

.animate--delay-1 {
  animation-delay: .1s;
}

.animate--delay-2 {
  animation-delay: .2s;
}

.animate--delay-3 {
  animation-delay: .3s;
}

@keyframes reveal {
  to {
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
  }
}

.logo {
  width: 200px;
  display: block;
}

.image {
  width: 100%;
  height: 100%;
  display: block;
}

.image--16-9 {
  aspect-ratio: 16 / 9;
}

.image--2-1 {
  aspect-ratio: 2;
}

.image--3-1 {
  aspect-ratio: 3;
}

.image img {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}

.btn, button {
  color: #fff;
  cursor: pointer;
  background: #ff0052;
  border: 1px solid #ff0052;
  padding: 1rem;
  text-decoration: none;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.btn:hover, button:hover {
  background: #6b6b6b;
}

.header {
  background: #f9f9f9;
  padding: 1rem 0;
  position: relative;
}

.header__inner {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer {
  font-size: .8rem;
}

.footer a {
  color: #fff;
  text-decoration: none;
}

@media (width <= 768px) {
  .nav__toggle {
    cursor: pointer;
    width: 2rem;
  }

  .nav__toggle span {
    background: #1e1e1e;
    width: 100%;
    height: 1px;
    transition: all .3s ease-in-out;
    display: block;
  }

  .nav__toggle span:nth-child(2) {
    margin: .25rem 0;
  }

  .nav__toggle--active span:first-child {
    transform: rotate(45deg);
  }

  .nav__toggle--active span:nth-child(2) {
    display: none;
  }

  .nav__toggle--active span:nth-child(3) {
    transform: rotate(-45deg);
  }

  .nav__inner {
    display: none;
  }

  .nav__inner--open {
    -webkit-overflow-scrolling: touch;
    z-index: 100;
    background: #ff0052;
    padding: 1rem;
    display: block;
    position: fixed;
    inset: 53px 0 0;
    overflow: scroll;
  }

  .nav__item {
    text-align: center;
    margin: 2rem 0;
  }

  .nav__item--has-submenu:after {
    content: "";
    font-family: icomoon;
  }

  .nav__link {
    color: #000;
    font-size: 1.25rem;
    text-decoration: none;
  }

  .nav__submenu {
    display: none;
  }

  .nav__submenu--open {
    background: #ff0052;
    display: block;
    position: absolute;
    inset: 0;
  }

  .nav__submenu-headline {
    color: #000;
    font-size: 1.25rem;
    font-weight: 700;
    text-decoration: none;
  }

  .nav__submenu-close {
    margin-bottom: 2rem;
    font-size: .8rem;
  }

  .nav__submenu-close:before {
    content: "";
    font-family: icomoon;
  }

  .nav__submenu-close:after {
    content: "Zurück";
  }
}

@media (width >= 769px) {
  .nav__toggle {
    display: none;
  }

  .nav__list {
    display: flex;
  }

  .nav__list--root > .nav__item > .nav__submenu {
    display: none;
  }

  .nav__list--root > .nav__item > .nav__submenu--open {
    display: block;
  }

  .nav__list--level-2 {
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 2vh 1rem 5vh;
    display: flex;
  }

  .nav__list--level-3 {
    display: block;
  }

  .nav__item--active {
    text-decoration: underline;
  }

  .nav__list--root .nav__item {
    cursor: pointer;
    margin: 0 1rem;
  }

  .nav__list--level-2 .nav__item {
    width: 20%;
    margin: 0 .25rem;
    font-weight: 700;
  }

  .nav__list--level-3 .nav__item {
    width: 100%;
    margin: .25rem 0;
    font-weight: 400;
  }

  .nav__link {
    color: #000;
    text-decoration: none;
  }

  .nav__submenu--open {
    background: #fff;
    border-bottom: 1px solid #000;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
  }

  .nav__submenu-headline {
    color: #000;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 1vh 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    display: block;
  }

  .nav__submenu-close {
    cursor: pointer;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 1vh 1rem;
    display: block;
  }

  .nav__submenu-close:before {
    content: "";
    font-family: icomoon;
  }
}

.modal {
  z-index: 200;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  display: none;
  position: fixed;
  inset: 0;
}

.modal.active {
  display: flex;
}

.modal__background {
  background: #000c;
  position: fixed;
  inset: 0;
}

.modal__window {
  background: #fff;
  width: 100%;
  max-width: 769px;
  height: 100%;
  max-height: 80vh;
  padding: 2rem;
  position: relative;
  overflow: scroll;
}

.modal__close {
  cursor: pointer;
  padding: .8rem;
  position: absolute;
  top: 0;
  right: 0;
}

.hero__inner {
  position: relative;
}

.hero__logo {
  margin-bottom: 2rem;
}

.hero__logo svg {
  max-width: 100%;
}

.hero__headline {
  padding-left: 2rem;
  font-size: 45px;
}

.hero__headline h1 {
  margin-bottom: 0;
  font-weight: 200;
}

@media (width >= 769px) {
  .hero__headline {
    max-width: 80%;
    padding-left: 4rem;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  .hero__headline h1 {
    letter-spacing: 30px;
    font-size: 65px;
    font-weight: 200;
  }
}

.hero__layer {
  mix-blend-mode: lighten;
  background: #ff0052;
  display: block;
  position: absolute;
  inset: 80px -1rem 0 50%;
}

@media (width >= 769px) {
  .hero__layer {
    top: 0;
  }
}

.hero__image {
  aspect-ratio: 3 / 2;
  margin-bottom: 1.5rem;
  position: relative;
}

.hero__image .image {
  height: 100%;
}

.hero__image:after {
  content: "";
  background: linear-gradient(#0000 50%, #1e1e1e 100%);
  position: absolute;
  inset: 0;
}

@media (width >= 769px) {
  .hero__image {
    aspect-ratio: 3;
    margin-bottom: 0;
  }
}

.text-image {
  background: #ff0052;
}

@media (width >= 769px) {
  .text-image__inner {
    display: flex;
  }

  .text-image__inner--reverse {
    flex-direction: row-reverse;
  }

  .text-image__image, .text-image__content {
    width: 50%;
  }
}

.gigs__row {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

@media (width >= 769px) {
  .gigs__row {
    flex-direction: row;
    gap: 2rem;
    margin-bottom: 0;
  }

  .gigs__content {
    width: 60%;
  }
}

.gigs__headline {
  border-bottom: 2px solid #ff0052;
  margin-bottom: 1.5rem;
}

.gigs__list {
  margin-bottom: 3rem;
}

@media (width >= 769px) {
  .gigs__list {
    font-size: 1.25rem;
  }
}

.gigs__image {
  order: -1;
  margin-bottom: 2rem;
  padding-bottom: 1.5rem;
  position: relative;
}

@media (width >= 769px) {
  .gigs__image {
    order: 2;
    width: 40%;
    margin-bottom: 0;
  }
}

.gigs__layer {
  aspect-ratio: 1;
  mix-blend-mode: lighten;
  background: #ff0052;
  width: 50%;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 0;
}

.gigs__list-inner {
  flex-direction: column;
  gap: 1.5rem;
  display: flex;
}

.gigs__item {
  justify-content: space-between;
  gap: .8rem;
  display: flex;
}

.gigs__item + .gigs__item {
  border-top: 1px solid #ff0052;
  padding-top: 1.5rem;
}

.gigs__item-date {
  flex: 0 0 50%;
  font-weight: 200;
}

.gigs__item-additional {
  color: #ff0052;
}

.bandmember__list {
  scroll-snap-type: x mandatory;
  gap: 1rem;
  margin: 0 1.5rem;
  display: flex;
  overflow: auto hidden;
}

@media (width >= 769px) {
  .bandmember__list {
    gap: 3rem;
  }
}

.bandmember__item {
  scroll-snap-align: start;
  color: #ff0052;
}

.bandmember__item-inner {
  aspect-ratio: 2 / 3;
  align-items: flex-end;
  width: 280px;
  display: flex;
  position: relative;
}

@media (width >= 769px) {
  .bandmember__item-inner {
    width: 400px;
  }
}

.bandmember__image {
  position: absolute;
  inset: 0;
}

.bandmember__image:after {
  content: "";
  background: linear-gradient(#0000 60%, #1e1e1e 100%);
  position: absolute;
  inset: 0;
}

.bandmember__image picture {
  width: 100%;
  height: 100%;
  display: block;
}

.bandmember__image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.bandmember__content {
  text-align: right;
  z-index: 1;
  width: 100%;
  padding-bottom: 1.5rem;
  padding-right: 20px;
}

.bandmember__name {
  text-transform: uppercase;
  hyphens: auto;
  font-size: 2rem;
  font-weight: 200;
}

@media (width >= 769px) {
  .bandmember__name {
    font-size: 3rem;
  }
}

.bandmember__job {
  text-transform: uppercase;
  font-size: 1.25rem;
}

.gallery__headline {
  margin-bottom: 5rem;
}

.gallery__list {
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;
  scrollbar-width: none;
  gap: 1rem;
  margin: 0 1.5rem;
  display: flex;
  overflow: auto;
}

@media (width >= 769px) {
  .gallery__list {
    gap: 3rem;
  }
}

.gallery__list::-webkit-scrollbar {
  display: none;
}

.gallery__item {
  scroll-snap-align: start;
  align-items: center;
  max-width: 90vw;
  height: 40vw;
  display: flex;
}

@media (width >= 769px) {
  .gallery__item {
    aspect-ratio: default;
    width: auto;
    height: 500px;
  }
}

.gallery__item img, .gallery__item video {
  width: auto;
  height: 100%;
}

.gallery__video {
  position: relative;
}

.gallery__play {
  background: #ff0052;
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.gallery__play-icon {
  fill: #fff;
  cursor: pointer;
  width: 4rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-35%, -45%, 0);
}

.contact__inner {
  flex-direction: column;
  display: flex;
}

@media (width >= 769px) {
  .contact__inner {
    flex-direction: row;
  }
}

.contact__image {
  margin-bottom: 1.5rem;
  position: relative;
}

@media (width >= 769px) {
  .contact__image {
    flex: 0 0 50%;
  }
}

.contact__image-layer {
  aspect-ratio: 1;
  mix-blend-mode: lighten;
  background: #ff0052;
  width: 60%;
  position: absolute;
  top: -1rem;
  right: 0;
}

@media (width >= 769px) {
  .contact__image-layer {
    right: -1rem;
  }
}

.contact__content {
  order: -1;
  margin-bottom: 3rem;
}

@media (width >= 769px) {
  .contact__content {
    flex: 0 0 50%;
    order: 2;
  }
}

.contact__text {
  margin-bottom: 1rem;
}

.slider__container {
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.slider__container::-webkit-scrollbar {
  display: none;
}

.slider__nav {
  justify-content: flex-end;
  display: flex;
}

.slider__nav svg {
  stroke: #fff;
  width: 3rem;
  height: 2rem;
}

.slider__next, .slider__prev {
  opacity: .4;
}

.slider__next.active, .slider__prev.active {
  cursor: pointer;
  opacity: 1;
}

.slider__nav {
  margin-top: 1rem;
}
/*# sourceMappingURL=main.css.map */
