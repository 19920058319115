.text-image {
  background: $color-second;
}

.text-image__inner {
  @include screen($md) {
    display: flex;

    &--reverse {
      flex-direction: row-reverse;
    }
  }
}

.text-image__image {
  @include screen($md) {
    width: 50%;
  }
}
.text-image__content {
  @include screen($md) {
    width: 50%;
  }
}
