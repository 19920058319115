@include screen($md) {
  .nav {
    // font-weight: $font-bold;
  }

  .nav__toggle {
    display: none;
  }

  .nav__inner {
  }

  .nav__list {
    display: flex;

    &--root {
      > .nav__item > .nav__submenu {
        display: none;

        &--open {
          display: block;
        }
      }
    }

    &--level-2 {
      width: 100%;
      max-width: $xl;
      margin: 0 auto;
      padding: 2vh $spacing-base 5vh $spacing-base;
      display: flex;
      align-items: flex-start;
    }

    &--level-3 {
      display: block;
    }
  }

  .nav__item {
    &--active {
      text-decoration: underline;
    }

    .nav__list--root & {
      cursor: pointer;
      margin: 0 $spacing-base;
    }

    .nav__list--level-2 & {
      width: 20%;
      font-weight: $font-bold;
      margin: 0 $spacing-xxs;
    }

    .nav__list--level-3 & {
      width: 100%;
      margin: $spacing-xxs 0;
      font-weight: $font-normal;
    }
  }

  .nav__link {
    color: $color-text;
    text-decoration: none;
  }

  .nav__submenu {
    &--open {
      position: absolute;
      width: 100%;
      top: 100%;
      right: 0;
      left: 0;
      background: $color-white;
      border-bottom: 1px solid $color-black;
    }
  }

  .nav__submenu-headline {
    width: 100%;
    max-width: $xl;
    margin: 0 auto;
    font-size: $text-lg;
    font-weight: $font-bold;
    display: block;
    padding: 1vh $spacing-base;
    color: $color-text;
    text-decoration: none;
  }

  .nav__submenu-close {
    display: block;
    cursor: pointer;
    width: 100%;
    max-width: $xl;
    margin: 0 auto;
    padding: 1vh $spacing-base;

    &:before {
      content: $icon-minimize-2;
      font-family: $icomoon-font-family;
    }
  }
}
