.slider__container {
  scroll-behavior: smooth;

  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
}

.slider__nav {
  display: flex;
  justify-content: flex-end;

  svg {
    stroke: #fff;
    width: 3rem;
    height: 2rem;
  }
}

.slider__next,
.slider__prev {
  opacity: 0.4;

  &.active {
    cursor: pointer;
    opacity: 1;
  }
}

.slider__nav {
  margin-top: 1rem;
}
