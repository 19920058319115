.contact__inner {
  //   flex-wrap: wrap;
  display: flex;
  flex-direction: column;

  @media (min-width: $md) {
    flex-direction: row;
  }
}

.contact__image {
  position: relative;
  margin-bottom: $spacing-lg;

  @media (min-width: $md) {
    // width: 50%;
    flex: 0 0 50%;
  }
}

.contact__image-layer {
  position: absolute;
  right: 0;
  top: -1rem;
  width: 60%;
  // transform: translate3d(15px, -15px, 0);
  aspect-ratio: 1 / 1;
  background: $color-second;
  mix-blend-mode: lighten;

  @media (min-width: $md) {
    right: -1rem;
  }
}

.contact__content {
  order: -1;
  margin-bottom: $spacing-xxl;

  @media (min-width: $md) {
    order: 2;
    // width: 50%;
    flex: 0 0 50%;
  }
}

.contact__text {
  margin-bottom: $spacing-base;
}
