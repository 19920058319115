.header {
  background: $color-light-gray;
  padding: $spacing-base 0;
  position: relative;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
