html {
  font-family: $font-family;
  font-weight: $font-normal;
  font-size: 16px;
  box-sizing: border-box;
}

body {
  // @apply bg-green;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // font-size: 1.125rem;
  font-size: $text-base;
  line-height: 1.6;
  background: $color-main;
  color: $color-white;

  *:focus {
    outline: none;
    background: none;
  }
}

* {
  // border: 1px solid red;
  box-sizing: border-box;
}

section,
.section {
  padding: clamp($spacing-xl, 10vw, $spacing-xxxxl) 0;
  overflow: hidden;
}

.container {
  max-width: $xl;
  margin: 0 auto;
  padding: 0 $spacing-base;

  @media (min-width: $md) {
    padding: 0 $spacing-xl;
  }

  &--small {
    max-width: $md;
  }
}

.container-fluid {
  padding: 0 5vw;
}

.row {
  margin-left: -$spacing-base;
  margin-right: -$spacing-base;

  @media (min-width: $md) {
    margin-left: -$spacing-xl;
    margin-right: -$spacing-xl;
    display: flex;
  }
}

.col {
  padding: 0 $spacing-base;

  @media (min-width: $md) {
    padding: 0 $spacing-xl;
  }
}

p {
  // @apply mb-4;
}

img {
  // @apply w-full;
  width: 100%;
  height: auto;

  // filter: grayscale(1);
}
