.gallery__headline {
  margin-bottom: $spacing-xxxl;
}

.gallery__list {
  display: flex;
  overflow: auto;
  gap: $spacing-base;
  scroll-snap-type: x mandatory;
  margin: 0 $spacing-lg;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: $md) {
    gap: $spacing-xxl;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.gallery__item {
  scroll-snap-align: start;
  //   aspect-ratio: 1 / 1;
  //   width: 300px;
  //   flex: 0 0 300px;
  height: 40vw;
  max-width: 90vw;
  display: flex;
  align-items: center;

  @media (min-width: $md) {
    // height: 400px;
    // flex: 0 0 auto;
    height: 500px;
    width: auto;
    aspect-ratio: default;
  }

  img,
  video {
    height: 100%;
    width: auto;
    // width: 100%;
    // height: 100%;
    // object-fit: contain;
    // object-position: center;
  }
}

.gallery__video {
  position: relative;
}

.gallery__play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  width: 10rem;
  height: 10rem;
  background: $color-second;
  border-radius: 50%;
}

.gallery__play-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-35%, -45%, 0);
  width: 4rem;
  fill: $color-white;
  cursor: pointer;
}
