@include screen($md, "max") {
  .nav {
    // font-weight: $font-bold;
  }

  .nav__toggle {
    width: $spacing-xl;
    cursor: pointer;

    span {
      display: block;
      width: 100%;
      height: 1px;
      background: $color-main;
      transition: all 0.3s ease-in-out;

      &:nth-child(2) {
        margin: $spacing-xxs 0;
      }
    }

    &--active {
      span:nth-child(1) {
        transform: rotate(45deg);
      }
      span:nth-child(2) {
        display: none;
      }
      span:nth-child(3) {
        transform: rotate(-45deg);
      }
    }
  }

  .nav__inner {
    display: none;

    &--open {
      display: block;
      position: fixed;
      inset: 0;
      top: 53px;
      background: $color-second;
      padding: $spacing-base;
      -webkit-overflow-scrolling: touch;
      overflow: scroll;
      z-index: 100;
    }
  }

  .nav__list {
    &--root {
    }

    &--level-2 {
    }

    &--level-3 {
    }
  }

  .nav__item {
    text-align: center;
    margin: $spacing-xl 0;

    &--has-submenu {
      &:after {
        content: $icon-arrow-right;
        font-family: $icomoon-font-family;
      }
    }
  }

  .nav__link {
    text-decoration: none;
    color: $color-text;
    font-size: $text-md;
  }

  .nav__submenu {
    display: none;

    &--open {
      display: block;
      //Delete for Full List view
      position: absolute;
      inset: 0;
      //------------------------
      background: $color-second;
    }
  }

  .nav__submenu-headline {
    text-decoration: none;
    color: $color-text;
    font-size: $text-md;
    font-weight: $font-bold;
  }

  .nav__submenu-close {
    font-size: $text-sm;
    margin-bottom: $spacing-xl;

    &:before {
      content: $icon-arrow-left;
      font-family: $icomoon-font-family;
    }

    &:after {
      content: "Zurück";
    }
  }
}
