.bandmember__list {
  display: flex;
  overflow: auto;
  overflow-y: hidden;
  gap: $spacing-base;
  scroll-snap-type: x mandatory;
  margin: 0 $spacing-lg;

  @media (min-width: $md) {
    gap: $spacing-xxl;
  }
}

.bandmember__item {
  scroll-snap-align: start;
  color: $color-second;
}

.bandmember__item-inner {
  display: flex;
  align-items: flex-end;
  aspect-ratio: 2 / 3;
  position: relative;
  // margin-right: $spacing-base;
  width: 280px;

  @media (min-width: $md) {
    width: 400px;
    // width: 530px;
  }
}

.bandmember__image {
  position: absolute;
  inset: 0;

  &:after {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 60%, $color-main 100%);
    content: "";
  }

  picture {
    width: 100%;
    height: 100%;
    display: block;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.bandmember__content {
  padding-bottom: $spacing-lg;
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // right: 30%;
  text-align: right;
  z-index: 1;
  width: 100%;
  padding-right: 20px;
}

.bandmember__name {
  font-size: $text-xl;
  text-transform: uppercase;
  font-weight: $font-light;
  hyphens: auto;

  @media (min-width: $md) {
    font-size: $text-xxl;
  }
}

.bandmember__job {
  font-size: $text-md;
  text-transform: uppercase;
}
